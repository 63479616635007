import React from 'react';
import './Site.css';
import NavBarCollapsible from './components/NavBarCollapsible'
import Carousel from './components/Carousel'
import FormContact from './components/FormContact'
import Nav from 'react-bootstrap/Nav';

import 'bootstrap/dist/css/bootstrap.min.css';

const listCarousel = [
  require('./assets/carousel/1.png'),
  require('./assets/carousel/2.png'),
  require('./assets/carousel/3.png'),
  require('./assets/carousel/4.png'),
  require('./assets/carousel/5.png'),
  require('./assets/carousel/6.png'),
  require('./assets/carousel/7.png'),
  require('./assets/carousel/8.png')
]

function Site(props) {
    
    const {windowHeight} = props

    return (
      <div className="Site">

        <NavBarCollapsible />

        <header id='home' className="Site-header" style={{minHeight:windowHeight}}>
          <h1>Zomb</h1>
          <p style={{marginBottom:50}}>Zomb est un jeu de gestion d'un groupe de survivant luttant face aux hordes de zombies</p>
          <Nav.Link className="offcanvas-link" target={'_blank'} href="https://play.google.com/store/apps/details?id=com.balou94.Zomb">
            <img
              style={{width:250}}
              src={require('./assets/google-play.png')}
              alt="Button Google Play"
            />
          </Nav.Link>
        </header>

        <main>

          <section id='about' className="Site-about" style={{minHeight:windowHeight}}>
        
            <div className="Site-about2">
              <h1>A propos</h1>
              <span style={{textAlign:'justify'}}>
              <p>Bienvenue dans Zomb, un jeu de survie captivant où votre objectif principal est de faire survivre un groupe de survivants le plus longtemps possible face à des vagues incessantes de hordes de zombies attaquant impitoyablement votre campement. Ce n'est pas tout, vous devrez également faire face à divers événements aléatoires qui testeront votre capacité à prendre des décisions rapides et judicieuses.</p>

              <p>Dans Zomb, la gestion des besoins vitaux de vos survivants sera essentielle pour assurer leur survie. Vous devrez vous assurer qu'ils ont suffisamment de nourriture et d'eau pour combattre la faim et la soif. La recherche de ressources, la chasse et la collecte de provisions seront des activités cruciales pour répondre à ces besoins vitaux.</p>

              <p>La protection de votre groupe contre les hordes de zombies sera également un élément clé du jeu. Vous devrez vous assurer que vos survivants disposent d'équipements de protection adéquats tels que des armures, des casques et des armes puissantes pour repousser les attaques et combattre efficacement les zombies. De plus, vous pourrez améliorer leurs compétences de combat et de survie au fur et à mesure qu'ils gagneront des niveaux, ce qui les rendra plus efficaces et résistants.</p>

              <p>La gestion de votre campement sera une autre facette cruciale de Zomb. Vous devrez renforcer les défenses de votre camp pour résister aux assauts des hordes de zombies. Construisez des barricades, des tours de guet et d'autres structures défensives pour protéger votre groupe. N'oubliez pas non plus de développer les infrastructures du campement, comme le puit, l’atelier où le générateur, pour maintenir la sécurité et la santé de vos survivants.</p>

              <p>En plus des attaques de zombies, vous serez confronté à des événements aléatoires qui ajouteront une dose de tension et de variété au jeu. Ces événements pourront inclure des incendies, des vols de ressources, des épidémies ou même des tempêtes qui menaceront la survie de votre groupe. Vous devrez faire preuve de réactivité et prendre des décisions cruciales pour assurer la pérennité de votre groupe.</p>

              <p>Dans Zomb, chaque jour compte. Les hordes de zombies deviendront de plus en plus agressives et difficiles à gérer au fil du temps, mettant votre capacité de survie à l'épreuve. Votre gestion avisée des besoins des survivants, des équipements, des compétences et de la sécurité du campement sera la clé de votre succès.</p>

              <p>Préparez-vous à plonger dans un monde post-apocalyptique rempli de défis, de choix difficiles et d'action intense. Pouvez-vous guider votre groupe de survivants vers la survie ultime face aux horde de zombies et aux événements aléatoires ? C'est à vous de jouer dans Zomb. Bonne chance !</p>

              <p>Zomb est un jeu que j'ai développé seul pendant mon temps libre. J'ai conçu Zomb avec soin, en veillant à offrir une expérience de jeu immersive et stimulante. J'ai créé  les mécanismes de jeu et les différentes fonctionnalités pour offrir aux joueurs une expérience unique.</p>

              <p>J'espère que vous apprécierez jouer à Zomb.</p>

              </span>
            </div>

            <Carousel listCarousel={listCarousel} />
          
          </section>

          <section id='contact' className="Site-contact" style={{minHeight:windowHeight}}>

            <h1>Contact</h1>

            <FormContact />

          </section>
          
        </main>

        <footer className="Site-footer">
          <p>© 2023 zomb.fr - Tous droits réservés</p>

        </footer>

      </div>
    );
}

export default Site;
