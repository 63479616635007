import React from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './FormContact.css';
import axios from 'axios';


class FormContact extends React.Component {

  state = {
    IsLoaded: true,
    email: '',
    subject: 'Autre',
    bodyEmail: '',
    mailSubmit: false
  }

  _changeEmail = (event) => {
    this.setState({email: event.target.value});
  }

  _changeSubject = (event) => {
    this.setState({subject: event.target.value});
  }

  _changeBody = (event) => {
    this.setState({bodyEmail: event.target.value});
  }

  _submitFormContact = async (event) => {

    this.setState({IsLoaded:false})

    event.preventDefault();

    await axios.post('https://zomb.fr/php/formContact.php', {
      email: this.state.email,
      subject: this.state.subject,
      body: this.state.bodyEmail,
    },{
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((response) => {
      console.log(response)
      this.setState({IsLoaded:true,mailSubmit:true})
    })
    .catch(function (error) {
      console.log(error);
    });


    this.setState({email:'',subject:'3',bodyEmail:''})
    event.target.reset()
  }

  render(){

    return (        
      <>
        {
            this.state.mailSubmit ? <span style={{color:'green',marginBottom:20,fontSize:25}}>
                Message envoyé avec succès !
            </span> : null
        }

        <Form onSubmit={ !this.state.IsLoaded ? null : (e)=>this._submitFormContact(e)}>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Adresse e-mail</Form.Label>
            <Form.Control type="email" required placeholder="E-mail" value={this.state.email} onChange={this._changeEmail} />
            <Form.Text className="text-muted">
                Nous ne partagerons jamais votre e-mail avec quelqu'un d'autre.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Objets</Form.Label>
            <Form.Select onChange={this._changeSubject}>
            <option value="Autre">Autre</option>
              <option value="Bug">Bug</option>
              <option value="Suggestion">Suggestion</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control as="textarea" rows={3} required placeholder="Message" value={this.state.bodyEmail} onChange={this._changeBody}/>
          </Form.Group>

          <Button variant="primary" className='Form-button' type="submit">
            Envoyer
          </Button>
        </Form>
      </>
    );
  }
}

export default FormContact;