import React from 'react';
import './App.css';
import Site from './Site'

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {

  state = {
    windowHeight: window.innerHeight
  }

  componentDidMount(){
    this.setState({windowHeight: window.innerHeight})
  }

  render () {

    return (
      <div className="App">
        <Site windowHeight={this.state.windowHeight} />
      </div>
    );
  }
}

export default App;
