import './Carousel.css';

import Carousel from 'react-bootstrap/Carousel';

function IndividualIntervalsExample(props) {

    let carouselItem = []
    props.listCarousel.forEach((element, index) => {
        carouselItem.push(
            <Carousel.Item key={index} interval={3000}>
                <img
                className="d-block w-100 carousel-item"
                src={props.listCarousel[index]}
                alt="First slide"
                />
            </Carousel.Item>
        )
    });
    
  return (
    <Carousel className="carousel-custom">
      {carouselItem}
    </Carousel>
  );
}

export default IndividualIntervalsExample;