import './NavBarCollapsible.css';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import image from '../assets/main_zomb.png';


function Collapsible( props ) {

  return (
    <Navbar collapseOnSelect fixed="top" expand="lg" variant="dark" className="mb-3 navbar-custom">
      <Container fluid>
        <Navbar.Brand className="navbar-brand" href="#home"><img src={image} alt='logo' height="25px" style={{marginRight:5}} />Zomb</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="justify-content-end flex-grow-1 pe-3 ">
            <Nav.Link className="offcanvas-link" href="#home">Accueil</Nav.Link>
            <Nav.Link className="offcanvas-link" href="#about">A propos</Nav.Link>
            <Nav.Link className="offcanvas-link" target={'_blank'} href="/wiki">Wiki</Nav.Link>
            <Nav.Link className="offcanvas-link" target={'_blank'} href="https://fr.tipeee.com/zombgame">Tipeee</Nav.Link>
            <Nav.Link className="offcanvas-link" href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Collapsible;